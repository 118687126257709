// 排班班次的 Select 下拉框列表选项
const productShiftOptions = [
  {
    value: 1,
    label: "白班"
  },
  {
    value: 2,
    label: "夜班"
  },
  {
    value: 3,
    label: "白转夜"
  },
  {
    value: 4,
    label: "夜转白"
  }
];

 

export { productShiftOptions };
