import axios from "axios";

/**
 * 获取排班历史主界面表格数据
 */
export const fetchProductPlanList = params => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/schedulingHistory",
    method: "GET",
    params
  });
};
