<template>
  <div id="page-product-plan-history">
    <!-- 筛选区块 -->
    <div class="common-filter-block">
            <div class="common-filter-item">
        <span style="margin-right: 10px">派工日期</span>
        <DatePicker
          style="width: 220px"
          type="daterange"
          placeholder="请选择日期范围"
          :clearable="false"
          v-model="listParam.dispatchDateRange"
          @on-change="handleDateChange"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">姓名</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.workerName"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>

      <div class="common-filter-item">
        <span style="margin-right: 10px">班次</span>
        <Select v-model="listParam.ztdwWorkTime" @on-change="getTableListData">
          <Option v-for="item in productShiftOptions" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
      </div>
    </div>
    <!-- 自定义的表格操作栏 -->
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="exportList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>
    <!-- 表格 -->
    <Table :border="true" :loading="loading" :columns="tableColumns" :data="tableData" />
    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
import { productShiftOptions } from "./constant";
import { fetchProductPlanList } from "./api";

export default {
  name: "productPlanHistory",
  data() {
    return {
      productShiftOptions, // 排班班次的 Select 下拉框列表选项

      // 首页表格列表的请求参数
      listParam: {
        machineNo: "",
        workerName: "",
        dispatchDateRange: [
          this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"),
          this.moment(new Date()).format("YYYY-MM-DD"),
        ],

        startDate: this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"),
        endDate: this.moment(new Date()).format("YYYY-MM-DD"),
        ztdwWorkTime: 1, // 1白班,2夜班,3白转夜，4夜转白

        pageNum: 1,
        pageSize: 10,
      },

      listDataTotal: 0, // 分页器数据总条数

      // 首页表格 配置
      loading: false,
      tableData: [],
      tableColumns: [
        {
          title: "序号",
          type: "index",
          width: 66,
        },
        {
          title: "时间",
          key: "date",
          minWidth: 120,
        },
        {
          title: "班次",
          key: "ztdwWorkTime",
          minWidth: 100,
        },
        {
          title: "员工姓名",
          key: "workerName",
          minWidth: 120,
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 200,
        },
        {
          title: "备注",
           key: "remarks",
          minWidth: 200,
        },
      ],
    };
  },
  created() {
    this.getTableListData();
  },
  methods: {
    handleDateChange(formatDate, DateObj) {
      this.listParam.startDate = formatDate[0];
      this.listParam.endDate = formatDate[1];

      this.getTableListData();
    },

    // 首页表格数据获取
    getTableListData() {
      this.loading = true;

      const { pageNum, pageSize, dispatchDateRange, ...param } = this.listParam;

      fetchProductPlanList({
        pageNum,
        pageSize,
        param,
      })
        .then((res) => {
          if (res.data.success === 1) {
            const { list } = res.data.body;

            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 导出表格
    exportList() {
      const { pageNum, pageSize, ...otherParam } = this.listParam;
      const { dispatchDateRange, ...data } = otherParam;
      const dataStr = this.qs.stringify(data);
      console.log(dataStr);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/order/ProductExecuteController/schedulingHistoryExport?${dataStr}`;
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";
</style>
<style lang="scss">
#page-device-view {
}
</style>
